<template>
  <div>
    <div class="notification-container">
      <div class="header">
        <div class="color-crm-bold font-sz-16 bold-700 font-text">
          {{ $i18n.locale === 'fr' ? 'Notifications' : 'Notifications' }}
        </div>
        <div class="message">
          <div v-if="loading" class="loading">
            {{ $t('loading') }}
          </div>
          <div v-if="getNotificationsError && !loading" class="error-msg">
            <ul v-if="Array.isArray(getNotificationsError)">
              <li v-for="(e, index) in getNotificationsError" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ getNotificationsError }}</span>
          </div>
        </div>
        <v-btn
          class="btn-filter small-btn"
          dark
          color="#5C2DD3"
          @click="refresh"
          :title="$i18n.locale === 'fr' ? 'Actualiser' : 'Refresh'"
        >
          <font-awesome-icon class="img-h-14" icon="sync-alt" />
        </v-btn>
      </div>
      <div class="loader-content" v-if="initLoading">
        <v-progress-circular
          indeterminate
          class="ml-3"
          color="#5C2DD3"
        ></v-progress-circular>
      </div>
      <div class="notifications" v-else>
        <!-- <div class="label" v-if="getNotification.length > 0">Plus récent</div> -->
        <div
          class="div-aucun-list"
          v-if="!getNotification || !getNotification.length"
        >
          <div class="titre">
            {{
              $i18n.locale === 'fr' ? 'Aucune Notification' : 'No Notification'
            }}
          </div>
        </div>
        <div v-if="getNotification && getNotification.length">
          <v-list dense class="list-ensemble">
            <v-list-item
              color="#5C2DD3"
              class="list-ensemble-item block-notif"
              @click="openActivity(notif.projet_id, i)"
              v-for="(notif, i) in getNotification"
              :key="i"
              :class="{
                'border-top-solid': i == 0,
                'gris-notif': notif.depuis == null
              }"
            >
              <v-list-item-content>
                <v-row>
                  <v-col cols="2" class="ml-2">
                    <div class="icon-type-notif">
                      <font-awesome-icon
                        class="img-h-19"
                        :icon="iconEntity(notif.activity_type)"
                      />
                    </div>
                  </v-col>
                  <v-col cols="7">
                    <div>
                      <router-link
                        class="route-notif"
                        :to="'/projects/' + notif.projet_id + '/activity'"
                      >
                        <div class="content-notif">
                          <div class="content">
                            <div class="titre-notif mb-1">
                              {{ notif.activity_name }}
                            </div>
                            <div>
                              {{ notif.contenu.split(' , ')[0] }} <br />
                            </div>
                          </div>
                        </div>
                      </router-link>
                    </div>
                    <div class="hour-color mt-1" v-if="notif.time">
                      {{ notif.activity_date_start | timeFromNow(notif) }}
                    </div>
                  </v-col>
                  <v-col cols="2">
                    <span
                      class="pointe-notif"
                      v-if="notif.depuis == null"
                    ></span>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
moment.locale('fr')

export default {
  name: 'Notifications',
  data() {
    return {
      popup: false,
      loading: false,
      initLoading: true
    }
  },
  computed: {
    ...mapGetters([
      'getNotification',
      'currentUser',
      'getNotificationsProcessing',
      'getNotificationsError'
    ]),
    iconEntity() {
      return function(type_activity) {
        if (type_activity === 'Pré visite') {
          return ['fas', 'briefcase']
        } else if (type_activity === 'Appel') {
          return ['fas', 'phone-alt']
        } else if (type_activity === 'Planning') {
          return ['fas', 'truck']
        } else {
          return ['fas', 'hourglass-start']
        }
      }
    }
  },
  methods: {
    ...mapActions(['fetchListNotification']),
    openActivity(projet_id) {
      this.$router.push('/projects/' + projet_id + '/activity')
    },
    refresh() {
      this.loading = true
      this.fetchListNotification(this.currentUser.id)
      setTimeout(() => {
        this.loading = false
      }, 1000)
    }
  },
  filters: {
    timeFromNow: function(value, notif) {
      if (!value) return ''
      var seconds = notif.time.split(':')[2]
      var minutes = notif.time.split(':')[1]
      var hours = notif.time.split(':')[0]
      var result = ''
      var starttime = moment(value, 'YYYY-MM-DD hh:mm:ss')
      var diff = ''
      if (notif.depuis == '1') {
        result = moment(value)
          .add(seconds, 'seconds')
          .add(minutes, 'minutes')
          .add(hours, 'hours')
        diff = moment
          .duration(starttime.diff(result))
          .locale('fr')
          .humanize(true)
      } else {
        result = moment(value)
          .subtract(seconds, 'seconds')
          .subtract(hours, 'hours')
          .subtract(minutes, 'minutes')
        diff = moment
          .duration(starttime.diff(result))
          .locale('fr')
          .humanize(true)
      }

      return diff
    }
  },
  async mounted() {
    this.initLoading = true
    await this.fetchListNotification(this.currentUser.id)
    this.initLoading = false
  }
}
</script>

<style lang="scss" scoped>
.notification-container {
  //   display: flex;
  //   flex-direction: column;
  padding: 20px;
  //   margin: auto;
  //   width: 70%;
  //   margin-top: 16px;
  //   background-color: #fff;
  //   border: 1px solid #fff;
  //   border-radius: 5px;
  // box-shadow: 0px 0px 40px 4px rgba(0, 0, 0, 0.16);

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 17px 2px;
  }

  .notifications {
    .v-list-item {
      padding: 0px 10px !important;
    }

    .titre-notif {
      color: #5c2dd3 !important;
      font-family: 'Montserrat' sans-serif;
      font-size: 13px;
      font-weight: 700;
    }
    .hour-color {
      color: #5c2dd3 !important;
      font-family: 'Montserrat' sans-serif;
      font-size: 13px;
      font-weight: 700;
    }
    .btn-notif {
      padding: 14px;
      margin-top: 10px;
    }
    .route-notif {
      color: black;
      font-family: 'Montserrat' sans-serif;
      font-size: 13px;
      text-decoration: none !important;
      &:hover {
        text-decoration: none !important;
      }
    }
    .router-link-active {
      text-decoration: none !important;
      &:hover {
        text-decoration: none !important;
      }
    }
    .content-notif {
      display: flex;
      align-items: center;
    }
    .pointe-notif {
      background: #5c2dd3;
      border-radius: 50%;
      position: absolute;
      right: 8px;
      top: 0px;
      display: block;
      width: 10px;
      height: 10px;
    }
    .icon-type-notif {
      border: 1px solid;
      padding: 12px 12px;
      border-radius: 36px;
      width: 45px;
      font-size: 14px;
      // margin-top: 10px;
      color: #5c2dd3;
    }
    .list-ensemble-item {
      border-top: 0px solid #d8d8d8;
      border-bottom: 1px solid #d8d8d8;
      border-left: 1px solid #d8d8d8;
      border-right: 1px solid #d8d8d8;
      border-color: #d8d8d8 !important;
      .v-list-item__content {
        padding: 12px 0 !important;
      }
      &.block-notif {
        &:hover {
          background-color: #5c2dd31a;
        }
        &.gris-notif {
          background-color: #5c2dd31a;
        }
      }
    }
    .list-ensemble {
      max-height: calc(100vh - 240px) !important;
      height: calc(100vh - 240px) !important;
      margin-bottom: 0;
      overflow-y: auto;
      overflow-x: auto;
      padding: 0px 0px;
      cursor: pointer;
    }
    .list-ensemble::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    .list-ensemble::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #f0eef8;
      border-radius: 4px;
      background-color: rgba(238, 238, 238, 1) !important;
    }
    .list-ensemble::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(189, 189, 189, 1);
    }
  }
}
</style>
